import React from 'react';
import NavbarTwo from '../components/Layouts/NavbarTwo';
import Heroslider from "../components/Heroslider"
import SEO from "../components/Seo"
import Footer from '../components/Layouts/Footer';
import { graphql} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
const index = ({ data }) => (
    <React.Fragment>
        
<SEO title={"Real Estate Developers | Property Builders | Credai Warangal"}  keywords={"CAD software solutions,CAD Solutions"} description={"Credai Warangal is a top real estate developers in Telangana. We construct residential apartment, luxury flats, villas, 2BHK and properties at best price."}  />
        <NavbarTwo />
   
     {/*   <MainBanner />*/}
        <Heroslider/>
      
      <div className="container">
        <div className="row">
          <div dangerouslySetInnerHTML={{ __html: data.wpPage?.content }} />
        </div>
      </div>
    
   
   
     { /*  <ServicesStyleOne /> */}
   
        {/*<FaqContent /> 
        <section className="our-loving-clients ptb-100 bg-f4f5fe">
                <div className="container-fulid">
                    <div className="section-title">
                        <h2>Industries Served
</h2>
                    </div>
                  
                    <div className="clients-logo-list align-items-center ">
                              <div className="single-clients-logo">
                            <a>
                                  <StaticImage src="../assets/images/clients/Industries-Medical Devices-CADVision-Engineers.png" alt="image" />
                                
                            </a>
                        </div>
                        <div className="single-clients-logo">
                            <a>
                               
                                  <StaticImage src="../assets/images/clients/Industries-Automotive-CADVision-Engineers.png" alt="image" />
                                
                            </a>
                        </div>
                        <div className="single-clients-logo">
                            <a>
                               
                                  <StaticImage src="../assets/images/clients/Industries-Transportation-CADVision-Engineers.png" alt="image" />
                          
                            </a>
                        </div>
                        <div className="single-clients-logo">
                            <a>
                               
                                  <StaticImage src="../assets/images/clients/Industries-Heavy-Engineering-CADVision-Engineers.png" alt="image" />
                               
                            </a>
                        </div>
                        <div className="single-clients-logo">
                            <a>
                   
                                  <StaticImage src="../assets/images/clients/Industries-Software-CADVision-Engineers.png" alt="image" />
                               
                            </a>
                        </div>
                        
                        <div className="single-clients-logo">
                            <a>
                   
                                  <StaticImage src="../assets/images/clients/Industries-Manufacturing-CADVision-Engineers.png" alt="image"/>
                               
                            </a>
                        </div>
                      
                      
                    </div>
                    
                </div>
            </section>*/}
       
              
        <Footer />
    </React.Fragment>
);
export default index;
export const query = graphql`
{
  wpPage(title: {eq: "Home"}) {

    
    content
  }
}
`
